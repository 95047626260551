import React, { FC } from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

interface LanguageProps {
  className?: string
}

export const Language: FC<LanguageProps> = ({ className }) => {
  const { i18n } = useTranslation()

  const changeOnEnglish = () => {
    if (i18n.language.includes('en')) {
      return
    }

    i18n.changeLanguage('en')
  }

  const changeOnRussian = () => {
    if (i18n.language.includes('ru')) {
      return
    }

    i18n.changeLanguage('ru')
  }

  return (
    <div className={cn(styles.buttons, className)}>
      <button
        className={cn(styles.button, i18n.language.includes('ru') && styles.button_active)}
        onClick={changeOnRussian}
      >
        ru
      </button>
      /
      <button
        className={cn(styles.button, i18n.language.includes('en') && styles.button_active)}
        onClick={changeOnEnglish}
      >
        eng
      </button>
    </div>
  )
}
