import autosnab from '@images/autosnab.png'
import volunteer from '@images/volunteer.png'
import climatec from '@images/climatec.png'
// import lastImage from '@images/lastImage.png'

export interface IPortfolioItem {
  tags: string[]
  title: string
  description: string
  image?: any
}

export const desktopPortfolioInfo: IPortfolioItem[] = [
  {
    image: climatec,
    tags: ['UX/UI', 'Mobile', 'Web'],
    title: 'climatec',
    description: 'climatec_desc',
  },
  {
    image: volunteer,
    tags: ['UX/UI', 'Mobile', 'Web'],
    title: 'volunteer',
    description: 'volunteer_desc',
  },
  {
    image: autosnab,
    tags: ['UX/UI', 'Mobile', 'Web'],
    title: 'autosnab',
    description: 'autosnab_desc',
  },
  // {
  //   image: lastImage,
  //   tags: [],
  //   title: 'This is last item',
  //   description: 'Save it here, do not touch',
  // },
]

export const mobilePortfolioInfo: IPortfolioItem[] = [
  {
    image: climatec,
    tags: ['UX/UI', 'Mobile', 'Web'],
    title: 'climatec',
    description: 'climatec_desc',
  },
  {
    image: volunteer,
    tags: ['UX/UI', 'Mobile', 'Web'],
    title: 'volunteer',
    description: 'volunteer_desc',
  },
  {
    image: autosnab,
    tags: ['UX/UI', 'Mobile', 'Web'],
    title: 'autosnab',
    description: 'autosnab_desc',
  },
  // {
  //   tags: [],
  //   title: 'This is last item',
  //   description: 'Save it here, do not touch',
  // },
]
