import React from 'react'
import styles from './styles.module.scss'

import { useTranslation } from 'react-i18next'
import { IPortfolioItem, mobilePortfolioInfo } from '../../info'
import { PortfolioItemMobile } from './components/PortfolioMobileItem'

export const PortfolioMobile = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.portfolio}>
      <h2 className={styles.title}>{t('portfolio')}</h2>

      <ul className={styles.list}>
        {mobilePortfolioInfo.map((item: IPortfolioItem, idx: number) => (
          <PortfolioItemMobile
            key={idx}
            image={item.image}
            tags={item.tags}
            title={t(item.title)}
            description={t(item.description)}
            isLast={idx === mobilePortfolioInfo.length - 1}
          />
        ))}
      </ul>
    </div>
  )
}
