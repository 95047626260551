import React, { useState } from 'react'

import burgerIcon from '@icons/burger.svg'
import { Modal } from '@components/Modal'
import { Language } from '@components/Language'
import { sectionsIds } from '@app/global-constants'
import { addHashToString } from '@helpers/addHastToString'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

export const Burger = () => {
  const [isOpened, setIsOpened] = useState(false)
  const { t } = useTranslation()

  const openBurger = () => {
    setIsOpened(true)
  }

  const closeBurger = () => {
    setIsOpened(false)
  }

  return (
    <div className={styles.burger}>
      <button className={styles.burger_btn} onClick={openBurger}>
        <img src={burgerIcon} alt='Burger' />
      </button>

      <Modal active={isOpened} setActive={closeBurger} crossFlag>
        <div className={styles.inner}>
          <nav className={styles.nav}>
            <ul className={styles.nav_list}>
              <li className={styles.nav_item} onClick={closeBurger}>
                <a href={addHashToString(sectionsIds.whatWeDo)} className={styles.nav_link}>
                  {t('what_we_doing')}
                </a>
              </li>
              <li className={styles.nav_item} onClick={closeBurger}>
                <a href={addHashToString(sectionsIds.portfolio)} className={styles.nav_link}>
                  {t('portfolio')}
                </a>
              </li>
              <li className={styles.nav_item} onClick={closeBurger}>
                <a href={addHashToString(sectionsIds.technologies)} className={styles.nav_link}>
                  {t('technology')}
                </a>
              </li>
              <li className={styles.nav_item} onClick={closeBurger}>
                <a href={addHashToString(sectionsIds.advantages)} className={styles.nav_link}>
                  {t('advantages')}
                </a>
              </li>
              {/* <li className={styles.nav_item} onClick={closeBurger}>
                <a href='#' className={styles.nav_link}>
                  {t('news')}
                </a>
              </li> */}
              <li className={styles.nav_item} onClick={closeBurger}>
                <a href={addHashToString(sectionsIds.startWork)} className={styles.nav_link}>
                  {t('submit_application')}
                </a>
              </li>
            </ul>
          </nav>

          <div className={styles.bottom}>
            <Language />
          </div>
        </div>
      </Modal>
    </div>
  )
}
