import Scrollbar from 'smooth-scrollbar'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { addHashToString } from '@helpers/addHastToString'
import { addDotToString } from '@helpers/addDotToString'

import { StopScrollPlugin } from '@lib/services/stop-scroll-plugin'
import { AnchorPlugin } from '@lib/services/anchor-plugin'

const portfolioSectionId = 'portfolio'
const scrollerId = 'scroller'
const portfolioImage = 'portfolio_image'
const portfolioBlock = 'portfolio_block'
const portfolioLastImage = 'portfolio_last_image'

const PORTFOLIO_SECTION_ID_WITH_HASH = addHashToString(portfolioSectionId)
const SCROLLER_ID_WITH_HASH = addHashToString(scrollerId)

const PORTFOLIO_IMAGE_WITH_DOT = addDotToString(portfolioImage)
const PORTFOLIO_BLOCK_WITH_DOT = addDotToString(portfolioBlock)
const PORTFOLIO_LAST_IMAGE_WITH_DOT = addDotToString(portfolioLastImage)

export const useGsap = () => {
  const selectors = {
    portfolioSectionId,
    scrollerId,
    portfolioImage,
    portfolioBlock,
    portfolioLastImage,
  }

  const unpauseScroll = () => {
    Scrollbar.getAll()[0].updatePluginOptions('stopScroll', { stopped: false })
  }

  const pauseScroll = () => {
    Scrollbar.getAll()[0].updatePluginOptions('stopScroll', { stopped: true })
  }

  const activateGsap = () => {
    gsap.registerPlugin(ScrollTrigger)
    Scrollbar.use(StopScrollPlugin)
    Scrollbar.use(AnchorPlugin)

    const scroller = document.getElementById(scrollerId)

    const bodyScrollBar = Scrollbar.init(scroller, {
      damping: 0.1,
    })

    ScrollTrigger.scrollerProxy(SCROLLER_ID_WITH_HASH, {
      scrollTop(value) {
        if (arguments.length) {
          bodyScrollBar.scrollTop = value
        }
        return bodyScrollBar.scrollTop
      },
    })

    pauseScroll()
    bodyScrollBar.addListener(ScrollTrigger.update)

    // Image blocks settings
    gsap.set(PORTFOLIO_IMAGE_WITH_DOT, {
      zIndex: (i, target, targets) => targets.length - i,
    })

    const images = gsap.utils.toArray(`${PORTFOLIO_IMAGE_WITH_DOT}:not(${PORTFOLIO_LAST_IMAGE_WITH_DOT})`)
    images.forEach((image: any, i) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: PORTFOLIO_SECTION_ID_WITH_HASH,
          scroller: SCROLLER_ID_WITH_HASH,
          start: () => 'top -' + window.innerHeight * (i + 0.5),
          end: () => '+=' + window.innerHeight,
          scrub: true,
          toggleActions: 'play none reverse none',
          invalidateOnRefresh: true,
        },
      })

      tl.to(image, { top: '-100%' })
    })

    // Text blocks settings
    gsap.set(PORTFOLIO_BLOCK_WITH_DOT, { zIndex: (i) => i })

    const texts = gsap.utils.toArray(PORTFOLIO_BLOCK_WITH_DOT)
    texts.forEach((text: any, i) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: PORTFOLIO_SECTION_ID_WITH_HASH,
          scroller: SCROLLER_ID_WITH_HASH,
          start: () => 'top -' + window.innerHeight * i,
          end: () => '+=' + window.innerHeight,
          scrub: true,
          toggleActions: 'play none reverse none',
          invalidateOnRefresh: true,
        },
      })

      tl.to(text, { duration: 0.33, opacity: 1, y: '0' }).to(text, { duration: 0.33, opacity: 0, y: '-50%' }, 0.66)
    })

    ScrollTrigger.create({
      trigger: PORTFOLIO_SECTION_ID_WITH_HASH,
      scroller: SCROLLER_ID_WITH_HASH,
      scrub: true,
      // markers: true,
      pin: true,
      start: () => 'top top',
      end: () => '+=' + (images.length + 1) * window.innerHeight,
      invalidateOnRefresh: true,
    })
  }

  return { selectors, pauseScroll, unpauseScroll, activateGsap }
}
