export interface ITechnologiesInfo {
  title: string
  subtitle: string
}

export const technologiesInfo: ITechnologiesInfo[] = [
  {
    title: 'Frontend',
    subtitle:
      'React.js, React-router, Redux, Redux-Toolkit, TypeScript, REST, Git Flow, SCSS, CSS, styled-components, Material UI, React-bootstrap',
  },
  {
    title: 'Blockchain',
    subtitle:
      'Сhialisp, solidity, blockchain, С++, Rust, Linux, Docker, docker-compose, docker swarm, gitlab CI/CD, REST API, Solidity, NEAR',
  },
  {
    title: 'Backend',
    subtitle:
      'Docker, Python, Django, aiohttp, PostgreSQL, MongoDB, Nginx, Redis, RabbitMQ, REST, JSON, GraphQL, WebSockets',
  },
  {
    title: 'Devops',
    subtitle: 'Ngnix, Kubernetes, CI/CD, Docker, Docker-compose, Jenkins, TeamCity, Apache, Zabbix',
  },
  {
    title: 'Mobile',
    subtitle:
      'Dart, Flutter, Java/Kotlin, OOP/SOLID, SQLite,  REST, Websockets, Design Patterns, DI, IoC, Regular Expression, BLOC etc',
  },
  {
    title: 'Data-science',
    subtitle: 'Python, sk-learn, pytorch, pandas, numpy, keras, tensorflow',
  },
]
