import React, { FC } from 'react'
import styles from './styles.module.scss'

import { useTranslation } from 'react-i18next'
import { IPortfolioItem } from '../../../../info'

interface PortfolioItemMobileProps extends IPortfolioItem {
  image: any
  isLast: boolean
}

export const PortfolioItemMobile: FC<PortfolioItemMobileProps> = ({ tags, title, description, isLast, image }) => {
  const { t } = useTranslation()

  return (
    <li className={styles.item}>
      {isLast ? (
        <div className={styles.all_container}>
          <a href='https://www.behance.net/antipoff_group' target='_blank' className={styles.all_link} rel='noreferrer'>
            <span>{t('view_work')}</span>
          </a>
        </div>
      ) : (
        <>
          <img src={image} alt='autosnab' className={styles.image} />
          <ul className={styles.small_list}>
            {tags.map((tag: string, idx: number) => (
              <li className={styles.small_item} key={idx}>
                {tag}
              </li>
            ))}
          </ul>

          <p className={styles.item_title}>{title}</p>
          <p className={styles.descr}>
            <span className={styles.descr_around}>&lt; description &gt;</span>
            <span className={styles.descr_text}>{description}</span>
            <span className={styles.descr_around}>&lt; /description &gt;</span>
          </p>
        </>
      )}
    </li>
  )
}
