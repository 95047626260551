import React, { FC } from 'react'
import styles from './styles.module.scss'

import cn from 'classnames'
import { Player } from '@lottiefiles/react-lottie-player'
import { IInfo } from '../../info'

export const WhatWeDoItem: FC<IInfo> = ({ animLink, title, subtitle, name }) => {
  const getItemClassname = () => {
    switch (name) {
      case 'blockchain':
        return cn(styles.item, styles.blockchain)
      case 'admin':
        return cn(styles.item, styles.admin)
      case 'outstaff':
        return cn(styles.item, styles.outstaff)
      case 'security':
        return cn(styles.item, styles.security)
      case 'web':
        return cn(styles.item, styles.web)
      case 'vision':
        return cn(styles.item, styles.vision)
    }
  }

  return (
    <li className={getItemClassname()}>
      <div className={styles.inner}>
        <Player src={animLink} className={styles.player} loop hover />

        <div className={styles.texts}>
          <h6 className={styles.title}>{title}</h6>
          <p className={styles.subtitle}>{subtitle}</p>
        </div>
      </div>
    </li>
  )
}
