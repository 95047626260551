import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'

import { Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import cn from 'classnames'

import { Home } from '@pages/Home'
import { Header } from '@components/Header'
import { Footer } from '@components/Footer'
import { useGsap } from '@lib/hooks/useGsap'
import { useWindowSize } from '@lib/hooks/useWindowSize'

const App = () => {
  const { width, bigTabletWidth } = useWindowSize()
  const isDesktop = width > bigTabletWidth

  const [loading, setLoading] = useState(true)
  const { selectors, unpauseScroll } = useGsap()

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)

      if (typeof width === 'number' && isDesktop) {
        unpauseScroll()
      }
    }, 1000)
  }, [width])

  return (
    <main className={styles.main}>
      <ToastContainer />
      <div className={cn(styles.spinner, !loading && styles.fadeOut)}>Loading...</div>

      <Header />
      <div className={styles.scroller} id={selectors.scrollerId}>
        <Switch>
          <Route path='/' exact component={Home} />
        </Switch>
        <Footer />
      </div>
    </main>
  )
}

export default App
