import React from 'react'
import styles from './styles.module.scss'

import { Hero, Partners, WhatWeDo, Portfolio, Technologies, Advantages, StartWork } from './components'

export const Home = () => {
  return (
    <div className={styles.home}>
      <Hero />
      <Partners />
      <WhatWeDo />
      <Portfolio />
      <Technologies />
      <Advantages />
      <StartWork />
    </div>
  )
}
