import React, { FC } from 'react'
import styles from './styles.module.scss'

import { ITechnologiesInfo } from '@pages/Home/components/Technologies/info'

export const TechnologiesDesktopItem: FC<ITechnologiesInfo> = ({ title, subtitle }) => {
  return (
    <li className={styles.item}>
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
    </li>
  )
}
