import React from 'react'
import styles from './styles.module.scss'

import { Container } from '@components/Container'
import { PortfolioDesktop, PortfolioMobile } from './components'

import { useGsap } from '@lib/hooks/useGsap'
import { useWindowSize } from '@lib/hooks/useWindowSize'

export const Portfolio = () => {
  const { width, bigTabletWidth } = useWindowSize()
  const isDesktop = width > bigTabletWidth
  const { selectors } = useGsap()

  return (
    <section className={styles.portfolio} id={selectors.portfolioSectionId}>
      <Container>
        {/* Извращения из-за того, что на мобиле НЕ должен активироваться скролл */}
        {typeof width === 'number' && isDesktop && <PortfolioDesktop />}
        {typeof width === 'number' && !isDesktop && <PortfolioMobile />}
      </Container>
    </section>
  )
}
