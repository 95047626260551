import React, { useState } from 'react'
import cn from 'classnames'

import { useTranslation } from 'react-i18next'
import logoImg from '@images/logo.svg'
import { Language } from '@components/Language'
import { useWindowSize } from '@lib/hooks/useWindowSize'
import { sectionsIds } from '@app/global-constants'
import { addHashToString } from '@helpers/addHastToString'
import styles from './styles.module.scss'

export const HeaderDesktop = () => {
  const { width } = useWindowSize()
  const { t } = useTranslation()
  const [currentLink, setCurretnLink] = useState('')

  return (
    <div className={styles.inner}>
      <a href='/' className={styles.logo_link}>
        <img src={logoImg} alt='Logo' />
        {t('team')} {width >= 1350 && <br />} {t('development')}
      </a>

      <nav className={styles.nav}>
        <ul className={styles.nav_list}>
          <li className={styles.nav_item}>
            <a
              href={addHashToString(sectionsIds.whatWeDo)}
              className={cn(styles.nav_link, { [styles.nav_link_active]: currentLink === sectionsIds.whatWeDo })}
              onClick={() => setCurretnLink(sectionsIds.whatWeDo)}
            >
              {t('what_we_doing')}
            </a>
          </li>
          <li className={styles.nav_item}>
            <a
              href={addHashToString(sectionsIds.portfolio)}
              className={cn(styles.nav_link, { [styles.nav_link_active]: currentLink === sectionsIds.portfolio })}
              onClick={() => setCurretnLink(sectionsIds.portfolio)}
            >
              {t('portfolio')}
            </a>
          </li>
          <li className={styles.nav_item}>
            <a
              href={addHashToString(sectionsIds.technologies)}
              className={cn(styles.nav_link, { [styles.nav_link_active]: currentLink === sectionsIds.technologies })}
              onClick={() => setCurretnLink(sectionsIds.technologies)}
            >
              {t('technology')}
            </a>
          </li>
          <li className={styles.nav_item}>
            <a
              href={addHashToString(sectionsIds.advantages)}
              className={cn(styles.nav_link, { [styles.nav_link_active]: currentLink === sectionsIds.advantages })}
              onClick={() => setCurretnLink(sectionsIds.advantages)}
            >
              {t('advantages')}
            </a>
          </li>
          {/* <li className={styles.nav_item}>
            <a 
              href={addHashToString(sectionsIds.startWork)} 
              className={cn(styles.nav_link, { [styles.nav_link_active]: currentLink === sectionsIds.startWork })}
              onClick={() => setCurretnLink(sectionsIds.startWork)}
            >
              {t('news')}
            </a>
          </li> */}
          <li className={styles.nav_item}>
            <a
              href={addHashToString(sectionsIds.startWork)}
              className={cn(styles.nav_link, { [styles.nav_link_active]: currentLink === sectionsIds.startWork })}
              onClick={() => setCurretnLink(sectionsIds.startWork)}
            >
              {t('submit_application')}
            </a>
          </li>
          <li className={styles.nav_item}>
            <Language />
          </li>
        </ul>
      </nav>
    </div>
  )
}
