import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from '@lib/locales/en.json'
import translationRU from '@lib/locales/ru.json'

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(LanguageDetector).init({
  // lng: 'en',

  // \u000A - перенос строки
  resources: {
    en: {
      translations: translationEN,
    },
    ru: {
      translations: translationRU,
    },
  },

  fallbackLng: 'en',
  debug: false,

  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    formatSeparator: ',',
  },
})

export default i18n
