import React, { useEffect } from 'react'
import cn from 'classnames'

import { useTranslation } from 'react-i18next'
import { Container } from '@components/Container'
import { IPortfolioItem, desktopPortfolioInfo } from '../../info'
import { PortfolioItemDesktop } from './components/PortfolioItemDesktop'
import { useGsap } from '@lib/hooks/useGsap'
import styles from './styles.module.scss'

export const PortfolioDesktop = () => {
  const { selectors, activateGsap } = useGsap()
  const { t } = useTranslation()
  const imageClassname = cn(selectors.portfolioImage, styles[selectors.portfolioImage])

  useEffect(() => {
    activateGsap()
  }, [])

  return (
    <section className={styles.portfolio} id={selectors.portfolioSectionId}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.images_wrapper}>
            {desktopPortfolioInfo.map((item: IPortfolioItem, idx: number) => (
              <img
                src={item.image}
                alt='autosnab'
                className={cn(imageClassname, styles.image, {
                  [selectors.portfolioLastImage]: idx === desktopPortfolioInfo.length - 1,
                })}
              />
            ))}
          </div>

          <ul className={styles.list}>
            {desktopPortfolioInfo.map((item: IPortfolioItem, idx: number) => (
              <PortfolioItemDesktop
                key={idx}
                tags={item.tags}
                title={t(item.title)}
                description={t(item.description)}
                isLast={idx === desktopPortfolioInfo.length - 1}
              />
            ))}
          </ul>
        </div>
      </Container>
    </section>
  )
}
