import React from 'react'

import { useTranslation } from 'react-i18next'
import { Container } from '@components/Container'
import { sectionsIds } from '@app/global-constants'
import { addHashToString } from '@helpers/addHastToString'
import styles from './styles.module.scss'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <section className={styles.footer}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.left}>
            <a href='mailto:a@antipoff.pro' className={styles.mail}>
              a@antipoff.pro
            </a>

            <ul className={styles.infos}>
              <li className={styles.info}>{t('contacts')}</li>
              <li className={styles.info}>{t('ITN')}</li>
            </ul>
          </div>

          <nav className={styles.right}>
            <ul className={styles.list}>
              <li className={styles.item}>
                <a href={addHashToString(sectionsIds.whatWeDo)} className={styles.link}>
                  {t('what_we_doing')}
                </a>
              </li>
              <li className={styles.item}>
                <a href={addHashToString(sectionsIds.portfolio)} className={styles.link}>
                  {t('portfolio')}
                </a>
              </li>
              <li className={styles.item}>
                <a href={addHashToString(sectionsIds.technologies)} className={styles.link}>
                  {t('technology')}
                </a>
              </li>
              <li className={styles.item}>
                <a href={addHashToString(sectionsIds.advantages)} className={styles.link}>
                  {t('advantages')}
                </a>
              </li>
              {/* <li className={styles.item}>
                <a href='#' className={styles.link}>
                  {t('news')}
                </a>
              </li> */}
              <li className={styles.item}>
                <a href={addHashToString(sectionsIds.startWork)} className={styles.link}>
                  {t('submit_application')}
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <p className={styles.subtitle}>{t('materials_use')}</p>
      </Container>
    </section>
  )
}
