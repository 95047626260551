import React from 'react'
import styles from './styles.module.scss'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Container } from '@components/Container'
import { sectionsIds } from '@app/global-constants'

export const Advantages = () => {
  const { t } = useTranslation()

  return (
    <section className={styles.advantages} id={sectionsIds.advantages}>
      <Container>
        <div className={styles.inner}>
          <h2 className={styles.title}>{t('our_advantages')}</h2>

          <div className={styles.wrapper}>
            <ul className={styles.list}>
              <li className={cn(styles.item, styles.first)}>
                <p className={styles.item_title}>{t('regular_reports')}</p>
                <p className={styles.item_subtitle}>{t('regular_reports_desc')}</p>
              </li>
              <li className={cn(styles.item, styles.second)}>
                <p className={styles.item_title}>{t('easy_chat')}</p>
                <p className={styles.item_subtitle}>{t('easy_chat_desc')}</p>
              </li>
              <li className={cn(styles.item, styles.third)}>
                <p className={styles.item_title}>{t('provide_guarantees')}</p>
                <p className={styles.item_subtitle}>{t('provide_guarantees_desc')}</p>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </section>
  )
}
