import { configureStore } from '@reduxjs/toolkit'
import landingSlice from './landingSlice'

export const store = configureStore({
  reducer: {
    landing: landingSlice,
  },
  devTools: process.env.MODE !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
