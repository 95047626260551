import React from 'react'
import styles from './styles.module.scss'

import ReactTypingEffect from 'react-typing-effect'
import { useTranslation } from 'react-i18next'
import { Container } from '@components/Container'
import { sectionsIds } from '@app/global-constants'

export const Hero = () => {
  const { t } = useTranslation()
  const typingWords = [
    t('blockchain'),
    t('sites'),
    t('voice_interfaces'),
    t('web_projects'),
    t('iot'),
    t('mobile_applications'),
  ]

  return (
    <section className={styles.hero} id={sectionsIds.hero}>
      <Container>
        <div className={styles.inner}>
          <h1 className={styles.title}>{t('professional_solutions')}</h1>

          <div className={styles.typing}>
            <span className={styles.typing_around}> &lt;/</span>
            <ReactTypingEffect
              text={typingWords}
              cursorClassName={styles.typing_cursor}
              speed={100}
              typingDelay={1000}
              eraseSpeed={100}
              eraseDelay={1000}
            />
            <span className={styles.typing_around}>&gt;</span>
          </div>
        </div>

        <div className={styles.wrapper}>
          <ul className={styles.list}>
            <li className={styles.item}>
              <span className={styles.item_number}>42+</span>
              <span className={styles.item_text}>{t('regular_customers')} </span>
            </li>
            <li className={styles.item}>
              <span className={styles.item_number}>365+</span>
              <span className={styles.item_text}>{t('completed_works')}</span>
            </li>
            <li className={styles.item}>
              <span className={styles.item_number}>28+</span>
              <span className={styles.item_text}>{t('developers_staff')}</span>
            </li>
          </ul>

          <div className={styles.bottom}>
            <p className={styles.subtitle}>{t('quality_development')}</p>
            <a href='https://pa-sales.bitrix24.site/' target='_blank' className={styles.bid} rel='noreferrer'>
              <span>{t('submit_application')}</span>
            </a>
          </div>
        </div>
      </Container>
    </section>
  )
}
