export interface IInfo {
  animLink: string
  title: string
  subtitle: string
  name: TName
}

export type TName = 'blockchain' | 'vision' | 'web' | 'outstaff' | 'admin' | 'security'

// animLink берётся из тэга script
export const info: IInfo[] = [
  {
    animLink: 'https://cdn.lordicon.com/qhviklyi.json',
    title: 'blockchain_projects',
    subtitle: 'blockchain_projects_desc',
    name: 'blockchain',
  },
  {
    animLink: 'https://cdn.lordicon.com/tyounuzx.json',
    title: 'computer_vision',
    subtitle: 'computer_vision_desc',
    name: 'vision',
  },
  {
    animLink: 'https://cdn.lordicon.com/qhgmphtg.json',
    title: 'websites',
    subtitle: 'websites_desc',
    name: 'web',
  },
  {
    animLink: 'https://cdn.lordicon.com/nobciafz.json',
    title: 'outstaffing',
    subtitle: 'outstaffing_desc',
    name: 'outstaff',
  },
  {
    animLink: 'https://cdn.lordicon.com/wrprwmwt.json',
    title: 'cybersecurity',
    subtitle: 'cybersecurity_desc',
    name: 'security',
  },
  {
    animLink: 'https://cdn.lordicon.com/zpxybbhl.json',
    title: 'administration',
    subtitle: 'administration_desc',
    name: 'admin',
  },
]
