import React from 'react'

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
    smallTabletWidth: 769,
    bigTabletWidth: 1000,
  })

  React.useEffect(() => {
    const handleResize = () => {
      const currentWidth = document.documentElement.clientWidth
      setWindowSize({ ...windowSize, width: currentWidth, height: document.documentElement.clientHeight })
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}
