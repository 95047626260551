import React from 'react'
import styles from './styles.module.scss'

import { useTranslation } from 'react-i18next'
import { Container } from '@components/Container'
import { sectionsIds } from '@app/global-constants'

export const StartWork = () => {
  const { t } = useTranslation()

  return (
    <section className={styles.startwork} id={sectionsIds.startWork}>
      <Container>
        <div className={styles.inner}>
          <h2 className={styles.title}>{t('start')}</h2>
          <a href='https://pa-sales.bitrix24.site/' target='_blank' className={styles.link} rel='noreferrer'>
            {t('write')}
          </a>
        </div>
      </Container>
    </section>
  )
}
