import React from 'react'
import ReactDOM from 'react-dom'

import './index.scss'

import { Provider } from 'react-redux'
import App from './components/App'
import { store } from '@app/redux/store'
import { BrowserRouter as Router } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root'),
)
