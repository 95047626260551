import React from 'react'
import styles from './styles.module.scss'

import { useTranslation } from 'react-i18next'
import { TechnologiesDesktopItem } from './components/TechnologiesDesktopItem'
import { technologiesInfo } from '../../info'

export const TechnologiesDesktop = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.inner}>
        <h2 className={styles.title}>
          {t('technology_desc')}
          <span>{t('technology')}</span>
          {t('technology_desc_p2')}
        </h2>
      </div>

      <ul className={styles.list}>
        {technologiesInfo.map((item, idx: number) => (
          <TechnologiesDesktopItem key={idx} title={item.title} subtitle={item.subtitle} />
        ))}
      </ul>
    </>
  )
}
