import React from 'react'
import styles from './styles.module.scss'

import Marquee from 'react-fast-marquee'
import { sectionsIds } from '@app/global-constants'
import {
  mriya,
  oneS,
  britannica,
  climatec,
  gazprom,
  kaidzen,
  lesnoy,
  pivnoy,
  rosterhit,
  sap,
  scanlite,
  statusfinance,
} from './images'

export const Partners = () => {
  return (
    <section className={styles.partners} id={sectionsIds.partners}>
      <Marquee speed={100} gradient={false}>
        <ul className={styles.list}>
          <li className={styles.item}>
            <img src={mriya} alt='Mriya' />
          </li>
          <li className={styles.item}>
            <img src={oneS} alt='1c' />
          </li>
          <li className={styles.item}>
            <img src={britannica} alt='Britannica' />
          </li>
          <li className={styles.item}>
            <img src={climatec} alt='Climatec' />
          </li>
          <li className={styles.item}>
            <img src={gazprom} alt='Gazprom' />
          </li>
          <li className={styles.item}>
            <img src={kaidzen} alt='Kaidzen' />
          </li>
          <li className={styles.item}>
            <img src={lesnoy} alt='Lesnoy resurs' />
          </li>
          <li className={styles.item}>
            <img src={pivnoy} alt='Pivnoy dvor' />
          </li>
          <li className={styles.item}>
            <img src={rosterhit} alt='Rosterhit' />
          </li>
          <li className={styles.item}>
            <img src={sap} alt='Sap' />
          </li>
          <li className={styles.item}>
            <img src={scanlite} alt='Scan lite' />
          </li>
          <li className={styles.item}>
            <img src={statusfinance} alt='Status finance' />
          </li>
        </ul>
      </Marquee>
    </section>
  )
}
