import React from 'react'
import styles from './styles.module.scss'

import { Container } from '@components/Container'
import { useWindowSize } from '@lib/hooks/useWindowSize'
import { HeaderDesktop, HeaderMobile } from './components'

export const Header = () => {
  const { width, bigTabletWidth } = useWindowSize()
  const isTablet = width <= bigTabletWidth

  return (
    <header className={styles.header}>
      <Container>{isTablet ? <HeaderMobile /> : <HeaderDesktop />}</Container>
    </header>
  )
}
