import { ScrollbarPlugin } from 'smooth-scrollbar'

export class StopScrollPlugin extends ScrollbarPlugin {
  static pluginName = 'stopScroll'

  static defaultOptions = {
    stopped: false,
  }

  transformDelta(delta) {
    return this.options.stopped ? { x: 0, y: 0 } : delta
  }
}
