import React from 'react'
import styles from './styles.module.scss'

import { useTranslation } from 'react-i18next'
import { TechnologiesMobileItem } from './components/TechnologiesMobileItem'
import { technologiesInfo } from '../../info'

export const TechnologiesMobile = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.inner}>
        <h2 className={styles.title}>
          {t('technology_desc')}
          <span>{t('technology')}</span>
          {t('technology_desc_p2')}
        </h2>
      </div>

      <div className={styles.wrapper}>
        <ul className={styles.list}>
          {technologiesInfo.map((item, idx: number) => (
            <TechnologiesMobileItem key={idx} title={item.title} subtitle={item.subtitle} />
          ))}
        </ul>
      </div>
    </>
  )
}
