import React from 'react'
import styles from './styles.module.scss'

import { Container } from '@components/Container'
import { WhatWeDoItem } from './components/WhatWeDoItem'

import { useTranslation } from 'react-i18next'
import { sectionsIds } from '@app/global-constants'
import { IInfo, info } from './info'

export const WhatWeDo = () => {
  const { t } = useTranslation()

  return (
    <section className={styles.whatWeDo} id={sectionsIds.whatWeDo}>
      <Container>
        <h2 className={styles.title}>
          {t('what_are_we')}
          <span>{t('doing')}</span>
        </h2>

        <ul className={styles.list}>
          {info.map((item: IInfo, idx: number) => (
            <WhatWeDoItem
              key={idx}
              animLink={item.animLink}
              title={t(item.title)}
              subtitle={t(item.subtitle)}
              name={item.name}
            />
          ))}
        </ul>
      </Container>
    </section>
  )
}
