import React from 'react'

import { Container } from '@components/Container'
import { TechnologiesDesktop, TechnologiesMobile } from './components'

import { useWindowSize } from '@lib/hooks/useWindowSize'
import { sectionsIds } from '@app/global-constants'

export const Technologies = () => {
  const { width, smallTabletWidth } = useWindowSize()
  const isTablet = width <= smallTabletWidth

  return (
    <section id={sectionsIds.technologies}>
      <Container>{isTablet ? <TechnologiesMobile /> : <TechnologiesDesktop />}</Container>
    </section>
  )
}
