import React from 'react'
import styles from './styles.module.scss'

import logoImg from '@images/logo.svg'
import { Burger } from './components/Burger'

export const HeaderMobile = () => {
  return (
    <div className={styles.inner}>
      <a href='/' className={styles.logo_link}>
        <img src={logoImg} alt='Logo' />
      </a>

      <Burger />
    </div>
  )
}
